import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { authlogin } from "../Redux/auth.action";
import { LOGIN_SUCCESS } from "../Redux/auth.types";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form, setform] = useState({
    name: "",
    password: "",
  });

  const [error, seterror] = useState(false);

  const handleChange = (e) => {
    let { name, value } = e.target;
    setform({ ...form, [name]: value });
  };

  // const handleSubmit = () => {
  //   if (form.name === "bfi" && form.password === "bfi@123") {
  //     navigate("/dashboard");
  //     toast.success("Login Successfully!", {
  //       autoClose: 3000,
  //     });
  //   } else {
  //     seterror(true);
  //   }
  // };

  const handleSubmit = async (e) => {
    let { name, password } = form;
    if (name && password) {
      dispatch(
        authlogin({
          userName: name,
          password: password,
        })
      ).then((res) => {
        if (res === LOGIN_SUCCESS) {
          navigate("/dashboard", { replace: true });
        } else if (res !== LOGIN_SUCCESS) {
          toast.error("Please enter your correct name and pasword.");
        }
      });
    } else {
      toast.error("Please enter your correct name and pasword!");
    }
  };
  return (
    <div className="main_header">
      <div className="first_div">
        <div className="flex_Box">
          <div className="left_Side">
            <img src="./logo.png" alt="logo" />
          </div>
          <div className="right_Side">
            <div>
              <p>Login</p>
              <input
                type="text"
                className="login_input"
                name="name"
                value={form.name}
                onChange={handleChange}
              />
              <input
                type="password"
                name="password"
                value={form.password}
                onChange={handleChange}
                className="login_input"
              />
              <button className="login_submit" onClick={handleSubmit}>
                Login
              </button>

              <span style={{ color: "red", fontFamily: "Calibri,sans-serif" }}>
                {error ? "Incorrect Username / password" : null}
              </span>

              <span>ForgotUsername / Password?</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
