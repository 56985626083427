import {
  Paper,
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Typography,
  Grid,
  TextField,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BaseURL } from "../global";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  borderRadius: "15px 15px 0 0",
  maxWidth: "95%",
  overflowX: "auto",
}));

const StyledTable = styled(Table)(({ theme }) => ({
  minWidth: 500,
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    minWidth: "auto",
    tableLayout: "auto",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.getContrastText(theme.palette.primary.dark),
  [theme.breakpoints.down("sm")]: {
    padding: "8px",
    fontSize: "14px",
    textAlign: "center",
  },
}));

const DocumentUploaderID = () => {
  const { id, docId, model } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const mode = searchParams.get("mode");
  const userData = JSON.parse(localStorage.getItem("auth"));
  const [rows, setRows] = useState([
    {
      id: Date.now(),
      documentName: "",
      documentId: null,
      file: null,
      fileName: "No file selected",
      fileUploadLink: "",
    },
  ]);
  // console.log(rows, "rows");
  const [equipment, setequipment] = useState([]);
  const [document, setdocument] = useState([]);

  const [serialno, setSerialNo] = useState("");
  const [selectedEquipmentModel, setSelectedEquipmentModel] = useState("");
  const [filteredDocument, setFilteredDocument] = useState([]);
  const [usersNameData, setusersNameData] = useState([]);

  const [equipmentSelect, setEquipmentSelect] = useState({
    equipmentId: null,
    equipmentName: "",
  });

  const [uniqueEquipmentNames, setUniqueEquipmentNames] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleAddRow = () => {
    const newRow = {
      id: Date.now(),
      documentName: "",
      documentId: null,
      file: null,
      fileName: "No file selected",
      fileUploadLink: "",
      isNew: true,
    };
    setRows((prevRows) => [...prevRows, newRow]);
  };

  const getData = async () => {
    try {
      let equipmentsResponse = await axios.get(BaseURL + "equipments");
      let equipmentsData = equipmentsResponse.data;
      let documentTypesResponse = await axios.get(BaseURL + "documentTypes");
      let documentTypesData = documentTypesResponse.data;

      const uniqueNamesSet = new Set(equipmentsData.map((item) => item.name));
      const uniqueNamesArray = Array.from(uniqueNamesSet);
      setUniqueEquipmentNames(uniqueNamesArray);
      setequipment(equipmentsData);
      setdocument(documentTypesData);

      if (userData) {
        if (userData.type === 1) {
          setFilteredDocument(documentTypesData);
        } else {
          const filteredDocumentData = documentTypesData.filter((item) => {
            if (item.allowedUserTypes === null) {
              return false;
            }
            const allowedUserTypes = item.allowedUserTypes.split(",");
            return allowedUserTypes.includes(userData?.id.toString());
          });
          setFilteredDocument(filteredDocumentData);
        }
      }

      if (id !== "0") {
        let equipmentDocsResponse = await axios.post(BaseURL + "dynamic", {
          query: `SELECT * FROM equipmentDocs WHERE equipmentId = ${id} AND modelNo = '${model}' ORDER BY documentTypeId ASC`,
        });
        // let equipmentDocsResponse = await axios.get(
        //   BaseURL + `equipmentDocs?_where=(equipmentId,eq,${Number(id)})`
        // );
        let equipmentDocsData = equipmentDocsResponse.data;
        let seleectedEquipment = equipmentDocsData?.find(
          (el) => el.id == docId
        );

        setSerialNo(seleectedEquipment?.SerialNo);
        sessionStorage.setItem('serialNo', JSON.stringify(seleectedEquipment?.SerialNo));
        const selectedEquipment = equipmentsData.find(
          (item) => item.id === seleectedEquipment?.equipmentId
        );
        setEquipmentSelect({
          equipmentId: selectedEquipment.id,
          equipmentName: selectedEquipment.name,
        });
        setSelectedEquipmentModel(seleectedEquipment?.modelNo);
        let filterQuipmentDocData = equipmentDocsData?.filter(
          (item) => item.SerialNo == seleectedEquipment?.SerialNo
        );
        setRows(
          filterQuipmentDocData?.map((item) => {
            const documentType = documentTypesData?.find(
              (doc) => doc.id === item.documentTypeId
            );
            return {
              id: item.id,
              documentName: documentType ? documentType.name : "",
              documentId: item.documentTypeId,
              file: null,
              createdBy: item.createdBy,
              fileName: item.documentURL.split("__").pop(),
              fileUploadLink: item.documentURL,
              lastModified: item.lastModified,
            };
          })
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFileUpload = (event, rowId) => {
    const file = event.target.files[0];
    const data = new FormData();
    data.append("fileToUpload", file);
    setLoading(true);
    axios
      .post(BaseURL + "fileUpload/fileUpload.php", data)
      .then((res) => {
        if (res.status === 200) {
          const updatedRows = rows.map((row) =>
            row.id === rowId
              ? {
                  ...row,
                  file,
                  fileName: file.name,
                  fileUploadLink: res.data,
                }
              : row
          );
          setRows(updatedRows);
          toast.success("File Uploaded Successfully!");
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Something Went Wrong!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDownload = (row) => {
    if (row.fileUploadLink) {
      axios({
        url: row.fileUploadLink,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const blob = new Blob([response.data]);
        saveAs(blob, row.fileName);
      });
    } else {
      toast.error("No file uploaded to download.");
    }
  };

  const handleEquipmentType = (e) => {
    const selectedEquipmentName = e.target.value;
    const selectedEquipment = equipment.find(
      (item) => item.name === selectedEquipmentName
    );

    if (selectedEquipment) {
      setEquipmentSelect({
        equipmentId: selectedEquipment.id,
        equipmentName: selectedEquipment.name,
      });

      setSelectedEquipmentModel("");
    }
  };

  const handleDocumenType = (e, rowId) => {
    const value = e.target.value;
    const selectedDocument = document.find((item) => item.name === value);

    if (selectedDocument) {
      const updatedRows = rows.map((r) =>
        r.id === rowId
          ? {
              ...r,
              documentName: value,
              documentId: selectedDocument.id,
            }
          : r
      );
      setRows(updatedRows);
    }
  };

  const handleDeleteRow = async (row) => {
    if (userData.type === 1 || userData.id === row.createdBy || row.isNew) {
      if (rows.length === 1) {
        return;
      }
      try {
        await axios.delete(BaseURL + `equipmentDocs/${Number(row.id)}`);
        const updatedRows = rows.filter((r) => r.id !== row.id);
        setRows(updatedRows);
      } catch (error) {
        console.error("Error deleting document:", error);
      }
    } else {
      toast.error("You cant delete this document.");
    }
  };

  const handleSubmit = async () => {
    for (const row of rows) {
      if (!row.documentName) {
        toast.error("Please select a document type for all rows.");
        return;
      }

      if (!row.file && !row.fileUploadLink) {
        toast.error("Please upload a file for all rows.");
        return;
      }
    }
    if (!equipmentSelect.equipmentId) {
      toast.error("Please select an equipment.");
      return;
    }
    if (!serialno) {
      toast.error("Please enter a serial number.");
      return;
    }

    try {
      if (id === "0") {
        const response = await axios.post(BaseURL + "dynamic", {
          query: `SELECT * FROM equipmentDocs`,
        });
        const equipmentDocsData = response.data;

        // Check if the modelNo, SerialNo, and equipmentId is unique
        const isDuplicateCombination = equipmentDocsData.some(
          (item) =>
            item.modelNo === selectedEquipmentModel &&
            item.SerialNo === serialno &&
            item.equipmentId === equipmentSelect.equipmentId
        );

        if (isDuplicateCombination) {
          toast.error(
            "SerialNo already exists. Please enter a unique serial number."
          );
          return;
        }
        const formData = rows.map((row) => ({
          equipmentId: equipmentSelect.equipmentId,
          documentTypeId: row.documentId,
          documentURL: row.fileUploadLink || "",
          SerialNo: serialno,
          modelNo: selectedEquipmentModel,
          createdBy: userData?.id,
        }));

        // console.log(formData, "formdaat");

        await axios.post(BaseURL + "equipmentDocs/bulk", formData);
        toast.success("Equipment Create Successfully!");
        navigate("/document_uploader");
      } else {

        let oldSerialNo = JSON.parse(sessionStorage.getItem("serialNo"));
        if (oldSerialNo != serialno) {
          const response = await axios.post(BaseURL + "dynamic", {
            query: `SELECT * FROM equipmentDocs`,
          });
          const equipmentDocsData = response.data;
          // Check if the modelNo, SerialNo, and equipmentId is unique
          const isDuplicateCombination = equipmentDocsData?.some(
            (item) =>
              item.modelNo === selectedEquipmentModel &&
              item.SerialNo === serialno &&
              item.equipmentId === equipmentSelect.equipmentId
          );
          if (isDuplicateCombination) {
            toast.error(
              "SerialNo already exists. Please enter a unique serial number."
            );
            return;
          }
        }

        const patchRequests = rows
          .filter((row) => !row.isNew)
          .map((row) => ({
            equipmentId: equipmentSelect.equipmentId,
            documentTypeId: row.documentId,
            documentURL: row.fileUploadLink || "",
            SerialNo: serialno,
            modelNo: selectedEquipmentModel,
            createdBy: row?.createdBy,
          }));

        // console.log("patchRequests", patchRequests);

        const patchPromises = patchRequests.map((patchData, index) => {
          const rowId = rows[index].id;
          return axios.patch(
            BaseURL + `equipmentDocs/${Number(rowId)}`,
            patchData
          );
        });

        await Promise.all(patchPromises);

        const newRows = rows.filter((row) => row.isNew);
        const newFormData = newRows.map((row) => ({
          equipmentId: equipmentSelect.equipmentId,
          documentTypeId: row.documentId,
          documentURL: row.fileUploadLink || "",
          SerialNo: serialno,
          modelNo: selectedEquipmentModel,
          createdBy: userData?.id,
        }));
        if (newRows.length > 0) {
          await axios.post(BaseURL + "equipmentDocs/bulk", newFormData);
        }

        toast.success("Document Updated Successfully!");
        navigate("/document_uploader");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went Wrong!");
    }
  };

  const getUsers = async () => {
    try {
      const response = await axios.get(BaseURL + "users");
      setusersNameData(response.data)
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    getData();
    if(id !=0){
      getUsers();
    }
  }, [id]);

  return (
    <Box>
      {mode === "edit" ? (
        <Typography variant="h5">Document Upload</Typography>
      ) : (
        <Typography variant="h5" sx={{ textAlign: "center", my: "2rem" }}>
          Document Uploaded
        </Typography>
      )}

      {mode === "edit" ? (
        <Box sx={{ my: 2 }}>
          <Grid sx={{ my: 2 }} container spacing={2}>
            <Grid item xs={12} md={4}>
              {id != 0 ? (
                <Typography variant="h6">
                  Machine Name :
                  <Typography
                    variant="span"
                    sx={{ fontSize: "16px", p: "0px 10px" }}
                  >
                    {equipmentSelect.equipmentName || ""}
                  </Typography>
                </Typography>
              ) : (
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Select Machine
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={equipmentSelect.equipmentName || ""}
                    onChange={handleEquipmentType}
                    label="Machine"
                  >
                    {uniqueEquipmentNames?.map((item) => (
                      <MenuItem key={item.id} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              {id != 0 ? (
                <Typography variant="h6">
                  Model Number :
                  <Typography
                    variant="span"
                    sx={{ fontSize: "16px", p: "0px 10px" }}
                  >
                    {selectedEquipmentModel || ""}
                  </Typography>
                </Typography>
              ) : (
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-simple-select-Model-Number">
                    Model Number
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-Model-Number"
                    id="1demo-simple-select-standard"
                    label="Equipment"
                    value={selectedEquipmentModel || ""}
                    onChange={(e) => setSelectedEquipmentModel(e.target.value)}
                  >
                    {equipment
                      .filter(
                        (item) => item.name === equipmentSelect.equipmentName
                      )
                      ?.map((item) => (
                        <MenuItem key={item.id} value={item.modelNo}>
                          {item.modelNo}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    variant="standard"
                    required
                    id="serailno"
                    label="Serial No"
                    name="serialno"
                    value={serialno || ""}
                    onChange={(e) => setSerialNo(e.target.value)}
                  />
                </FormControl>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box sx={{ my: 2, textAlign: "left", width: "90%", mx: "auto" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">
                Machine Name:
                <Typography
                  variant="span"
                  sx={{ fontSize: "16px", p: "0px 10px" }}
                >
                  {equipmentSelect.equipmentName || ""}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">
                Model Number:
                <Typography
                  variant="span"
                  sx={{ fontSize: "16px", p: "0px 10px" }}
                >
                  {selectedEquipmentModel || ""}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">
                Serial Number:
                <Typography
                  variant="span"
                  sx={{ fontSize: "16px", p: "0px 10px" }}
                >
                  {serialno || ""}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}

      {mode === "edit" ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "flex-start", md: "flex-end" },
            padding: "10px",
            my: 2,
          }}
        >
          <Button variant="contained" onClick={handleAddRow}>
            Add
          </Button>
        </Box>
      ) : null}

      <Modal
        open={loading}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box
          style={{
            width: "100%",
            height: "100vh",
            bgcolor: "background.paper",
            border: "2px solid cadetblue",
            padding: "30px",
            textAlign: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      </Modal>

      <StyledTableContainer component={Paper} sx={{ m: "10px auto" }}>
        <StyledTable aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Index</StyledTableCell>
              <StyledTableCell>Document Name</StyledTableCell>
              {mode === "read" ? <StyledTableCell>Date</StyledTableCell> : null}
              {mode === "edit" ? (
                <StyledTableCell>Upload</StyledTableCell>
              ) : null}
              {mode === "edit" ? (
                <StyledTableCell>Filename</StyledTableCell>
              ) : null}
              {mode === "edit" ? (
                <StyledTableCell>Uploaded By</StyledTableCell>
              ) : null}
              <StyledTableCell>Action</StyledTableCell>
              {mode === "edit" ? (
                <StyledTableCell>Delete Row</StyledTableCell>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, ind) => (
              <TableRow key={row?.id}>
                <TableCell>{ind + 1}</TableCell>
                {mode === "edit" ? (
                  <TableCell>
                    {userData.type === 1 ||
                    row.createdBy === userData.id ||
                    row.isNew ||
                    id === "0" ? (
                      <FormControl variant="standard" sx={{ minWidth: 170 }}>
                        <InputLabel id={`document-${row?.id}-label`}>
                          Select Document
                        </InputLabel>
                        <Select
                          labelId={`document-${row?.id}-label`}
                          value={row?.documentName}
                          onChange={(e) => handleDocumenType(e, row?.id)}
                          label="Document"
                        >
                          {filteredDocument.map((item) => (
                            <MenuItem key={item.id} value={item.name}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      row?.documentName
                    )}
                  </TableCell>
                ) : (
                  <TableCell
                    sx={{ textTransform: "capitalize", fontSize: "12px" }}
                  >
                    {row?.documentName}
                  </TableCell>
                )}
                {mode === "read" ? (
                  <TableCell sx={{ fontSize: "12px" }}>
                    {moment(row?.lastModified).format("DD-MM-YYYY")}
                  </TableCell>
                ) : null}

                {mode === "edit" ? (
                  <TableCell>
                    <input
                      disabled={
                        !(
                          userData.type === 1 ||
                          row.createdBy === userData.id ||
                          row.isNew ||
                          id === "0"
                        )
                      }
                      style={{ display: "none" }}
                      id={`upload-input-${row?.id}`}
                      type="file"
                      onChange={(e) => handleFileUpload(e, row?.id)}
                    />
                    <label htmlFor={`upload-input-${row?.id}`}>
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        disabled={
                          !(
                            userData.type === 1 ||
                            row.createdBy === userData.id ||
                            row.isNew ||
                            id === "0"
                          )
                        }
                      >
                        <CloudUploadIcon />
                      </IconButton>
                    </label>
                  </TableCell>
                ) : null}
                {mode === "edit" ? (
                  <TableCell>{row?.fileName}</TableCell>
                ) : null}
                {mode === "edit" ? (
                  <TableCell>{usersNameData?.find(user => user.id == row?.createdBy)?.userName || ""}</TableCell>
                ) : null}
                <TableCell>
                  <Stack direction="row" spacing={{ xs: 1 }}>
                    <IconButton aria-label="view" color="primary">
                      <a
                        href={row?.fileUploadLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <VisibilityIcon />
                      </a>
                    </IconButton>
                    <IconButton aria-label="download" color="success">
                      <DownloadIcon onClick={() => handleDownload(row)} />
                    </IconButton>
                  </Stack>
                </TableCell>
                {mode === "edit" ? (
                  <TableCell>
                    <IconButton aria-label="download" color="error">
                      <DeleteIcon onClick={() => handleDeleteRow(row)} />
                    </IconButton>
                  </TableCell>
                ) : null}
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>

      {mode === "edit" ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "flex-start", md: "flex-end" },
            padding: "10px",
            my: 2,
          }}
        >
          <Button variant="contained" color="success" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};

export default DocumentUploaderID;
