import { useEffect, useState } from "react";
import {
  Paper,
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  TablePagination,
  Button,
  TextField,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import { BaseURL } from "../../global";
import { Link } from "react-router-dom";
const columns = [
  { id: "id", label: "Sr. No.", minWidth: 20 },
  { id: "userName", label: "User Name", minWidth: 40 },
  { id: "password", label: "Password", minWidth: 40 },
  { id: "type", label: "Type", minWidth: 40 },
  { id: "modules", label: "Modules", minWidth: 60 },
  { id: "action", label: "Action", minWidth: 40 },
];
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  borderRadius: "15px 15px 0 0",
  maxWidth: "100%",
}));

const StyledTable = styled(Table)(({ theme }) => ({
  minWidth: 550,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.getContrastText(theme.palette.primary.dark),
}));

const UserList = () => {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchBy, setSearchBy] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const getData = async () => {
    try {
      let userResponse = await axios.get(BaseURL + "users?_sort=-id");
      let usersData = userResponse.data;
      setRows(usersData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this User?"
    );
    if (!confirmDelete) {
      return;
    }
    try {
      await axios.delete(BaseURL + `users/${id}`);
      toast.success("User Delete Successfully!");
      getData();
    } catch (error) {
      console.error("Error deleting users:", error);
      toast.error("Something Went Wrong!");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const calculateIndex = (pageIndex, rowIndex, rowsPerPage) => {
    return pageIndex * rowsPerPage + rowIndex + 1;
  };

  const handleSearch = () => {
    let query = "";
    if (searchBy === "userName") {
      query = `SELECT * FROM users WHERE userName LIKE '${searchKey}%'`;
    } else if (searchBy === "type") {
      const typeValue =
        searchKey.toLowerCase() === "admin"
          ? 1
          : searchKey.toLowerCase() === "user"
          ? 2
          : null;

      if (typeValue !== null) {
        query = `SELECT * FROM users WHERE type = ${typeValue}`;
      } else {
        toast.error("Invalid type input. Please enter 'Admin' or 'User'.");
        return;
      }
    }
    axios
      .post(BaseURL + "dynamic", {
        query: query,
      })
      .then((res) => setRows(res.data))
      .catch((err) => toast.error(err));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Link to="/users/0">
          <Button variant="contained">Add User</Button>
        </Link>
        <Box>
          <Stack direction="row" spacing={2} alignItems="center">
            <Autocomplete
              sx={{
                m: 0,
                minWidth: 180,
                marginLeft: "10px",
              }}
              size="small"
              id="combo-box-demo"
              options={[
                { key: "userName", label: "userName" },
                { key: "type", label: "type" },
              ]}
              renderInput={(params) => (
                <TextField {...params} label="Search By" />
              )}
              onInputChange={(event, newInputValue) => {
                setSearchBy(newInputValue);
                if (!newInputValue.trim()) {
                  getData();
                  setSearchKey("");
                }
              }}
            />
            <TextField
              id="outlined-basic"
              label={"enter " + searchBy}
              size="small"
              variant="outlined"
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
            />
            <Button variant="contained" onClick={handleSearch}>
              Search
            </Button>
          </Stack>
        </Box>
      </Stack>

      <br />
      <br />
      <Box>
        <StyledTableContainer component={Paper}>
          <StyledTable aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <b>{column.label}</b>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? rows.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : rows
              )?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {calculateIndex(page, index, rowsPerPage)}
                  </TableCell>
                  <TableCell>{row.userName}</TableCell>
                  <TableCell>{row.password}</TableCell>
                  <TableCell>{row.type == 1 ? "Admin" : "User"}</TableCell>
                  <TableCell>{row.modules}</TableCell>
                  <TableCell sx={{ p: 0 }}>
                    <Stack direction="row">
                    <Tooltip
                        title="Edit User"
                        placement="top-start"
                      >
                        <Button component={Link} sx={{minWidth:0}}
                        to={`/users/${row.id}`}
                        >                         
                          <EditIcon color="success" />
                          </Button>
                          </Tooltip>
                          <Tooltip
                        title="Delte User"
                        placement="top-start"
                      >
                        <Button color="error"
                          onClick={() => handleDelete(row.id)} 
                        >
                        <DeleteIcon />
                        </Button>
                      </Tooltip>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        ></TablePagination>
      </Box>
    </>
  );
};

export default UserList;
