import {
  Paper,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Stack,
  Modal,
  TablePagination,
  Typography,
  Button,
  TextField,
  Autocomplete,
  Grid,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import { toast } from "react-toastify";
import QRCode from "react-qr-code";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useReactToPrint } from "react-to-print";
import { BaseURL, DOMAIN } from "../global";
import { Link } from "react-router-dom";
import { toPng } from "html-to-image";
import ImageTracer from "imagetracerjs";

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  borderRadius: "15px 15px 0 0",
  maxWidth: "100%",
  // height: 450,
}));

const StyledTable = styled(Table)(({ theme }) => ({
  minWidth: 550,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.getContrastText(theme.palette.primary.dark),
}));

const qrModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

const DocumentUploader = () => {
  const [rows, setRows] = useState([]);
  const [filterRows, setfilterRows] = useState([]);
  const [equipment, setequipment] = useState([]);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const handleClose = () => setQrModalOpen(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedModelNo, setselectedModelNo] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const printComponentRef = useRef();
  const [serialNo, setserialNo] = useState(null);
  const [docID, setDocID] = useState(null);
  const [searchBy, setSearchBy] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const userData = localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : null;

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    onBeforeGetContent: () => {
      const printButton = document.getElementById("printButton");
      if (printButton) {
        printButton.style.display = "none";
      }
    },
    onAfterPrint: () => {
      const printButton = document.getElementById("printButton");
      if (printButton) {
        printButton.style.display = "block";
      }
    },
  });
     
  const convertPngToSvg = async (pngDataUrl) => {
    return new Promise((resolve) => {
      ImageTracer.loadImage(pngDataUrl, function (canvas) {
        var imgd = ImageTracer.getImgdata(canvas);
        var svgstr = ImageTracer.imagedataToSVG(imgd, { scale: 1 });
        resolve(svgstr);
      });
    });
  };

  const handleCopyUrl = () => {
    const urlToCopy = `${DOMAIN}#/document_uploader/${selectedRowId}/${docID}/${selectedModelNo}?mode=read`;

    navigator.clipboard.writeText(urlToCopy)
      .then(() => {
        // console.log('URL copied to clipboard:', urlToCopy);
        const tooltip = document.createElement('div');
    tooltip.textContent = 'URL copied to clipboard!';
    tooltip.style.position = 'fixed';
    tooltip.style.backgroundColor = '#333';
    tooltip.style.color = '#fff';
    tooltip.style.padding = '5px';
    tooltip.style.borderRadius = '5px';
    tooltip.style.zIndex = '9999';
    tooltip.style.top = '20%';
    tooltip.style.left = '50%';
    tooltip.style.transform = 'translate(-50%, -50%)';
    document.body.appendChild(tooltip);
    setTimeout(() => {
        document.body.removeChild(tooltip);
    }, 2000);
      })
      .catch((error) => {
        // console.error('Error copying URL to clipboard:', error);
        const tooltip = document.createElement('div');
        tooltip.textContent = 'URL copied again!';
        tooltip.style.position = 'fixed';
        tooltip.style.backgroundColor = '#333';
        tooltip.style.color = '#fff';
        tooltip.style.padding = '5px';
        tooltip.style.borderRadius = '5px';
        tooltip.style.zIndex = '9999';
        tooltip.style.top = '20%';
        tooltip.style.left = '50%';
        tooltip.style.transform = 'translate(-50%, -50%)';
        document.body.appendChild(tooltip);
        setTimeout(() => {
            document.body.removeChild(tooltip);
        }, 2000);
      });
  };

  const handleDownload = async () => {
    const node = document.getElementById("componentToPrint");
    try {
      const dataUrl = await toPng(node);

      // Convert PNG to SVG using ImageTracer
      const svgString = await convertPngToSvg(dataUrl);

      const blob = new Blob([svgString], { type: "image/svg+xml" });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "traced_image.svg";

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    } catch (error) {
      console.error("Error generating SVG:", error);
    }
  };

  const getEquipmentName = (equipmentId) => {
    const foundEquipment = equipment.find((eq) => eq.id === equipmentId);
    return foundEquipment ? foundEquipment.name : "";
  };

  const getData = async () => {
    try {
      let equipmentDocsResponse = await axios.get(
        BaseURL + `equipmentDocs?_sort=-lastModified`
      );
      let equipmentsResponse = await axios.get(BaseURL + "equipments");
      let equipmentDocsData = equipmentDocsResponse.data;
      let equipmentsData = equipmentsResponse.data;
      setequipment(equipmentsData);

      const uniqueEquipmentIds = new Set();
      const distinctEquipmentIds = [];

      equipmentDocsData.forEach((item) => {
        const key = `${item.equipmentId}-${item.modelNo}-${item.SerialNo}`;
        if (!uniqueEquipmentIds.has(key)) {
          uniqueEquipmentIds.add(key);
          distinctEquipmentIds.push(item);
        }
      });

      // equipmentDocsData.forEach((item) => {
      //   if (!uniqueEquipmentIds.has(item.equipmentId)) {
      //     uniqueEquipmentIds.add(item.equipmentId);
      //     distinctEquipmentIds.push(item);
      //   }
      // });

      setRows(distinctEquipmentIds);
      // console.log(distinctEquipmentIds, "distinctEquipmentIds");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDelete = async (row) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this document?"
    );
    if (!confirmDelete) {
      return;
    }
    try {
      let data = {
        query: `DELETE FROM equipmentDocs WHERE equipmentId = ${row.equipmentId} AND modelNo = '${row.modelNo}' AND SerialNo = '${row.SerialNo}'`,
        // query: `delete from equipmentDocs where equipmentId = ${row.equipmentId}`,
      };
      axios
        .post(BaseURL + `dynamic`, data)
        .then((res) => {
          toast.success("Equipment Delete Successfully!");
          getData();
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
      getData();
    } catch (error) {
      console.error("Error deleting equipment:", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const calculateIndex = (pageIndex, rowIndex, rowsPerPage) => {
    return pageIndex * rowsPerPage + rowIndex + 1;
  };

  const handleSearch = () => {
    // axios
    //   .post(BaseURL + "dynamic", {
    //     query: `SELECT * FROM equipmentDocs WHERE  ${searchBy} LIKE '${searchKey}%'`,
    //   })
    //   .then((res) => setRows(res.data))
    //   .catch((err) => toast.error(err));
    const filteredRows = rows.filter((row) => {
      const searchKeyLowerCase = searchKey.toLowerCase();
      return (
        row.modelNo.toLowerCase().includes(searchKeyLowerCase) ||
        row.SerialNo.toLowerCase().includes(searchKeyLowerCase)
      );
    });
    setfilterRows(filteredRows);
  };

  useEffect(() => {
    getData();
  }, [selectedRowId]);
  
  useEffect(()=>{
    setfilterRows(rows);
  },[rows]);

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        {userData?.type == 1 && (
          <Link to="/document_uploader/0/0/0?mode=edit">
            <Button variant="contained">Document Upload</Button>
          </Link>
        )}
        <Box>
          <Stack direction="row" spacing={2} alignItems="center">
            <Autocomplete
              sx={{
                m: 0,
                minWidth: 180,
                marginLeft: "10px",
              }}
              size="small"
              id="combo-box-demo"
              options={[
                { key: "modelNo", label: "modelNo" },
                { key: "SerialNo", label: "SerialNo" },
              ]}
              renderInput={(params) => (
                <TextField {...params} label="Search By" />
              )}
              onInputChange={(event, newInputValue) => {
                setSearchBy(newInputValue);
                if (!newInputValue.trim()) {
                  getData();
                  setSearchKey("");
                }
              }}
            />
            <TextField
              id="outlined-basic"
              label={"enter " + searchBy}
              size="small"
              variant="outlined"
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
            />
            <Button variant="contained" onClick={handleSearch}>
              Search
            </Button>
          </Stack>
        </Box>{" "}
      </Stack>
      <br />
      <br />
      <Box>
        <StyledTableContainer component={Paper}>
          <StyledTable aria-label="simple table">
            <TableHead sx={{ position: "sticky", top: "0", zIndex: "9" }}>
              <TableRow>
                <StyledTableCell>Index</StyledTableCell>
                <StyledTableCell>Machine Name</StyledTableCell>
                <StyledTableCell>Model Number</StyledTableCell>
                <StyledTableCell>Serial Number</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? filterRows.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filterRows
              )?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {calculateIndex(page, index, rowsPerPage)}
                  </TableCell>
                  <TableCell>{getEquipmentName(row.equipmentId)}</TableCell>
                  <TableCell>{row.modelNo}</TableCell>
                  <TableCell>{row?.SerialNo}</TableCell>
                  <TableCell>
                    <Stack direction="row">
                    <Tooltip
                        title="Qr Scan"
                        placement="top-start"
                      >
                        <Button sx={{minWidth:0}}>
                        <QrCode2Icon color="primary"
                          onClick={() => {
                            setSelectedRowId(row.equipmentId);
                            setselectedModelNo(row.modelNo);
                            setserialNo(row.SerialNo);
                            setDocID(row.id);
                            setQrModalOpen(true);
                          }}
                        />
                        </Button>
                      </Tooltip>
                      <Tooltip
                        title="Edit Document"
                        placement="top-start"
                      >
                        <Button
                        sx={{minWidth:0}}
                         component={Link}
                        to={`/document_uploader/${row.equipmentId}/${row.id}/${row.modelNo}?mode=edit`}
                        >
                        <EditIcon color="success" />
                        </Button>
                      </Tooltip>
                      <Tooltip
                        title="Delete Machine Name"
                        placement="top-start"
                      >
                        <Button sx={{minWidth:0}} onClick={() => handleDelete(row)}>
                          <DeleteIcon color="error" />
                        </Button>
                      </Tooltip>
                      <Tooltip
                        title="Spare History"
                        placement="top-start"
                      >
                        <Button
                        sx={{minWidth:0}}
                          color="primary"
                          component={Link}
                          to={`/sparehistorys/${row.equipmentId}/${row.id}`}
                        >
                          <BookmarkIcon />
                        </Button>
                      </Tooltip>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        ></TablePagination>
      </Box>
        <Modal
          ref={printComponentRef}
          open={qrModalOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div>
            <Box sx={qrModalStyle}>
              <div id="componentToPrint">
                <img src={"/qrTemplate.png"} alt="img" height={"550px"} />
                <Typography
                  style={{
                    position: "absolute",
                    top: "24%",
                    left: "0%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 1,
                    width: "100%",
                    fontWeight: "600",
                    fontSize: "12px",
                  }}
                >
                  Machine Name: {getEquipmentName(selectedRowId)}
                  <br />
                  Model Number : {selectedModelNo} <br />
                  Serial Number : {serialNo}
                </Typography>
                <Paper
                  elevation={0}
                  style={{
                    position: "absolute",
                    top: "51%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1,
                  }}
                >
                  <QRCode
                    value={`${DOMAIN}#/document_uploader/${selectedRowId}/${docID}/${selectedModelNo}?mode=read`}
                    // value={`http://localhost:3000/#/document_uploader/${selectedRowId}/${docID}/${selectedModelNo}?mode=read`}
                    align="center"
                    size={200}
                  />
                </Paper>
              </div>
              <Grid container style={{width:"100%", display: "flex",justifyContent:"space-between" }}>
              <Grid item xs={6}>
              <Button
                id="printButton"
                variant="contained"
                size="small"
                onClick={handlePrint}
              >
                Download
              </Button>
              </Grid>
              <Grid item xs={6} align="right">
              <Button
               id="copyUrlButton"
                variant="contained"
                size="small"
                onClick={handleCopyUrl} 
                startIcon={<ContentCopyIcon />}               
              >
               Copy URL
              </Button>
              </Grid>
              </Grid>

            </Box>
          </div>
        </Modal>
    </>
  );
};

export default DocumentUploader;
