import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Paper,
  Box,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  TablePagination,
  Button,
  TextField,
  Grid,
  FormControl,
  Typography,
} from "@mui/material";
import { BaseURL } from "../global";
import axios from "axios";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  borderRadius: "15px 15px 0 0",
  maxWidth: "100%",
}));

const StyledTable = styled(Table)(({ theme }) => ({
  minWidth: 550,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.getContrastText(theme.palette.primary.dark),
}));

const SpareHistory = () => {
  const { id ,docId } = useParams();
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [machineData, setMachineData] = useState(null);
  const [machineName, setMachineName] = useState(null);
  const [spareform, setSpareForm] = useState({
    name: "",
    remarks: "",
    spareDate: null,
  });
  // console.log(spareform);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSpareForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const getData = async () => {
    try {
      let spareResponse = await axios.get(
        BaseURL + `spareHistory?_where=(equipmentDocId,eq,${id})&_sort=-id`
      );
      let machineResponse = await axios.get(
        BaseURL + `equipmentDocs/${docId}`
      );
      let machineName = await axios.get(
        BaseURL + `equipments/${id}`
      );
      let machineData = machineResponse?.data?.[0];
      let machineNameData = machineName?.data?.[0];
      let equipmentsData = spareResponse?.data;

      let spareFilter = equipmentsData?.filter((item)=>item.serialNo == machineData.SerialNo && item.modelNo == machineData.modelNo);
      // console.log({equipmentsData,machineNameData,machineData,spareFilter})      
      setRows(spareFilter);
      setMachineData(machineData);
      setMachineName(machineNameData);
      // console.log({ machineData,machineNameData });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this Spare?"
    );
    if (!confirmDelete) {
      return;
    }
    try {
      await axios.delete(BaseURL + `spareHistory/${id}`);
      toast.success("Spare Delete Successfully!");
      getData();
    } catch (error) {
      console.error("Error deleting equipment:", error);
    }
  };

  const handleSubmit = async () => {
    if (!spareform.name || !spareform.remarks || !spareform.spareDate) {
      toast.error("Please fill in all required fields.");
      return;
    }
    try {
      let payload = {
        equipmentDocid: id,
        serialNo:machineData?.SerialNo || null,
        modelNo:machineData?.modelNo || null,
        spareName: spareform.name,
        spareDate: spareform.spareDate,
        remark: spareform.remarks,
      };
      await axios.post(BaseURL + "spareHistory", payload);
      toast.success("Spare added successfully!");
      getData();
      setSpareForm({ name: "", remarks: "", spareDate: null });
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, [id]);

  return (
    <div>
      <Paper sx={{ p: 1 }}>
        <Grid sx={{ my: 2 }} container spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="h6">
              Machine Name :
              <Typography
                variant="span"
                sx={{ fontSize: "16px", p: "0px 10px" }}
              >
                {machineName?.name || ""}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">
              Model Number :
              <Typography
                variant="span"
                sx={{ fontSize: "16px", p: "0px 10px" }}
              >
                {machineData?.modelNo || ""}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">
              Serial Number :
              <Typography
                variant="span"
                sx={{ fontSize: "16px", p: "0px 10px" }}
              >
                {machineData?.SerialNo || ""}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <Grid sx={{ my: 2 }} container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <TextField
              required
              id="name"
              label="Spare name "
              variant="outlined"
              name="name"
              multiline
              minRows={2}
              maxRows={4}
              value={spareform.name || ""}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <TextField
              required
              id="name"
              label="Remarks"
              variant="outlined"
              name="remarks"
              multiline
              minRows={2}
              maxRows={4}
              value={spareform.remarks || ""}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              classNames="mr-2"
            >
              <DatePicker
                label="Select Spare Date"
                value={spareform.spareDate ? new Date(spareform.spareDate) : ""}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(newDate) => {
                  setSpareForm((prevForm) => ({
                    ...prevForm,
                    spareDate: newDate
                      ? moment(newDate).format("YYYY-MM-DD")
                      : null,
                  }));
                }}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} alignItems={"center"}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Grid>
      </Grid>
      <Box>
        <StyledTableContainer component={Paper}>
          <StyledTable aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Index</StyledTableCell>
                <StyledTableCell>Spare Name</StyledTableCell>
                <StyledTableCell>Remarks</StyledTableCell>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? rows.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : rows
              )?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.spareName}</TableCell>
                  <TableCell>{row.remark}</TableCell>
                  <TableCell>
                    {moment(row.spareDate).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell sx={{ p: 0 }}>
                    <Stack direction="row" spacing={{ xs: 1, sm: 1, md: 2 }}>
                      <IconButton aria-label="delete" color="error">
                        <DeleteIcon onClick={() => handleDelete(row.id)} />
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        ></TablePagination>
      </Box>
    </div>
  );
};

export default SpareHistory;
