import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import DocumentTypeMasterID from "./Component/DocumentTypeMasterID";
import DocumentUploaderID from "./Component/DocumentUploaderID";
import EquipmentID from "./Component/EquipmentID";
import Sidebar from "./Component/Sidebar";
import RequireAuth from "./HOC/RequiredAuth";
import Dashboard from "./Pages/Dashboard";
import DocumentTypeMaster from "./Pages/DocumentTypeMaster";
import DocumentUploader from "./Pages/DocumentUploader";
import EquipmentMaster from "./Pages/EquipmentMaster";
import Home from "./Pages/Home";
import UserList from "./Pages/user/UserList";
import Users from "./Pages/user/Users";
import SpareHistory from "./Component/SpareHistory";

const App = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const mode = queryParams.get("mode");
  return (
    <>
      {mode === "read" ? (
        <Routes>
          <Route
            path="/document_uploader/:id/:docId/:model"
            element={<DocumentUploaderID />}
          />
        </Routes>
      ) : (
        <>
          {location.pathname === "/" ? (
            <Routes>
              <Route path="/" element={<Home />} />
            </Routes>
          ) : (
            <Sidebar>
              <Routes>
                <Route
                  path="/dashboard"
                  element={
                    <RequireAuth>
                      <Dashboard />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/equipment_master"
                  element={
                    <RequireAuth>
                      <EquipmentMaster />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/userlist"
                  element={
                    <RequireAuth>
                      <UserList />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/users/:id"
                  element={
                    <RequireAuth>
                      <Users />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/equipment_master/:id"
                  element={
                    <RequireAuth>
                      <EquipmentID />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/document_type_master"
                  element={
                    <RequireAuth>
                      <DocumentTypeMaster />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/document_type_master/:id"
                  element={
                    <RequireAuth>
                      <DocumentTypeMasterID />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/document_uploader"
                  element={
                    <RequireAuth>
                      <DocumentUploader />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/sparehistorys/:id/:docId"
                  element={
                    <RequireAuth>
                      <SpareHistory />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/document_uploader/:id/:docId/:model"
                  element={<DocumentUploaderID />}
                />
              </Routes>
            </Sidebar>
          )}
        </>
      )}

      <ToastContainer />

      <footer id="footerScroll">
        <span>
          <CallIcon style={{ fontSize: "0.6rem" }} /> Pravin Kashid, +91 97698
          47865
        </span>
        <span>
          Copyright © 2022 All Right Reserved By&nbsp; <b> BFI</b>
        </span>
        <span>
          <EmailIcon style={{ fontSize: "0.6rem" }} /> Info@bfiinfotech.co.in
        </span>
      </footer>
    </>
  );
};

export default App;
