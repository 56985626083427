import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Checkbox,
  Toolbar,
  Button,
} from "@mui/material";
import React from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import "./user.css";
import axios from "axios";
import { BaseURL } from "../../global";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
const moduleList = [
  {
    id: 1,
    name: "Dashboard",
  },
  {
    id: 2,
    name: "Machine Master",
  },
  {
    id: 3,
    name: "Document Type Master",
  },
  {
    id: 4,
    name: "Document Upload",
  },
  {
    id: 5,
    name: "Users",
  },
];
const userTypes = [
  {
    id: 1,
    name: "Admin",
  },
  {
    id: 2,
    name: "Users",
  },
];
const Users = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [modules, setModules] = React.useState(null);
  const [type, setType] = React.useState("Admin");
  const [selectedModules, setSelectedModules] = React.useState(null);
  const [passVal, setPasswordVal] = React.useState([0, 0, 0, 0, 0, 0]);

  const getData = async () => {
    if (Number(id) == 0) {
      setUserName("");
      setPassword("");
    } else {
      try {
        let userResponse = await axios.get(BaseURL + `users/${Number(id)}`);
        let userData = userResponse.data[0];
        // console.log(userData, "userData");
        setUserName(userData.userName);
        setPassword(userData.password);
        setType(userData.type === 1 ? "Admin" : "Users");
        if (userData.modules) {
          const moduleIds = userData.modules.split(",").map(Number);
          const selectedModules = moduleList.filter((module) =>
            moduleIds.includes(module.id)
          );
          setSelectedModules(selectedModules);
        }
        const passwordValue = userData.password || "";
        const vals = [
          passwordValue.match(/[a-z]/g) ? 1 : 0,
          passwordValue.match(/[A-Z]/g) ? 1 : 0,
          passwordValue.match(/[0-9]/g) ? 1 : 0,
          passwordValue.length >= 8 ? 1 : 0,
          passwordValue.length <= 32 ? 1 : 0,
        ];
        setPasswordVal(vals);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const initModules = () => {
    setModules(moduleList);
  };

  const onSubmit = () => {
    const jsonData = {
      userName,
      password,
      type: type === "Admin" ? 1 : 2,
    };

    if (selectedModules && selectedModules.length > 0) {
      jsonData.modules = selectedModules.map((m) => m.id).join(",");
    }else {
      alert("Please select at least one module");
      return; 
    }

    if (id === "0") {
      axios
        .post(BaseURL + "users", jsonData)
        .then((res) => {
          toast("User Added Successfully!");
          navigate("/userlist");
        })
        .catch((err) => {
          toast.error("Something Went Wrong : ");
          console.log("Something Went Wrong : ", err);
        });
    } else {
      axios
        .patch(BaseURL + `users/${Number(id)}`, jsonData)
        .then((res) => {
          toast("User Updated Successfully!");
          navigate("/userlist");
        })
        .catch((err) => {
          toast.error("Something Went Wrong : ");
          console.log("Something Went Wrong : ", err);
        });
    }
  };  

  React.useEffect(() => {
    initModules();
    if (id !== "0") {
      getData();
    }
  }, [id]);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ textAlign: "left" }}>
        Add user
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="Username *"
            value={userName}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setUserName(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="Password *"
            value={password}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              let vals = passVal;

              let lowerCaseLetters = /[a-z]/g;
              e.target.value.match(lowerCaseLetters)
                ? (vals[0] = 1)
                : (vals[0] = 0);

              // Validate capital letters
              let upperCaseLetters = /[A-Z]/g;
              e.target.value.match(upperCaseLetters)
                ? (vals[1] = 1)
                : (vals[1] = 0);

              // Validate numbers
              let numbers = /[0-9]/g;
              e.target.value.match(numbers) ? (vals[2] = 1) : (vals[2] = 0);

              // Validate length
              e.target.value.length >= 8 ? (vals[3] = 1) : (vals[3] = 0);
              e.target.value.length <= 32 ? (vals[4] = 1) : (vals[4] = 0);

              setPasswordVal(vals);
              setPassword(e.target.value);
            }}
          />
          <div>
            <p id="letter" class={passVal[0] ? "valid" : "invalid"}>
              A <b>lowercase</b> letter
            </p>
            <p id="capital" class={passVal[1] ? "valid" : "invalid"}>
              A <b>capital (uppercase)</b> letter
            </p>
            <p id="number" class={passVal[2] ? "valid" : "invalid"}>
              A <b>number</b>
            </p>
            <p id="lengthMin" class={passVal[3] ? "valid" : "invalid"}>
              Minimum <b>8 characters</b>
            </p>
            <p id="lengthMax" class={passVal[4] ? "valid" : "invalid"}>
              Maximum <b>32 characters</b>
            </p>
          </div>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={userTypes.map(({ id, name }) => ({
              label: name,
            }))}
            value={type}
            renderInput={(params) => (
              <TextField {...params} label="User Type *" />
            )}
            onInputChange={(event, newInputValue) => {
              setType(newInputValue);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            multiple
            fullWidth
            id="checkboxes-tags-demo"
            size="small"
            options={modules || []}
            value={selectedModules || []}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />

                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select modules (optional)"
                placeholder="select multiple modules"
              />
            )}
            onChange={(event, value) => {
              if (value.length) {
                setSelectedModules(value);
              } else {
                setSelectedModules(null);
              }
            }}
          />
        </Grid>
      </Grid>

      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          disabled={
            userName === "" ||
            password === "" ||
            passVal.reduce((s, v) => s + v, 0) < 5
          }
          onClick={() => {
            onSubmit();
          }}
        >
          Save user
        </Button>
      </Toolbar>
    </Paper>
  );
};

export default Users;
