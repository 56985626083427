import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { BaseURL } from "../global";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const DocumentTypeMasterID = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [selectedModules, setSelectedModules] = useState(null);
  const [modules, setModules] = useState([]);

  const getData = async () => {
    try {
      // setModules(null);

      let usersResponse = await axios.get(BaseURL + "users");
      let userData = usersResponse.data;
      let filteredData = userData.filter((user) => user.type === 2);
      const usernamesAndIds = filteredData.map((user) => ({
        id: user.id,
        name: user.userName,
      }));
      setModules(usernamesAndIds);

      if (Number(id) === 0) {
        setName("");
        setSelectedModules([]);
      } else {
        let documentTypesResponse = await axios.get(
          BaseURL + `documentTypes/${Number(id)}`
        );
        let documentTypesData = documentTypesResponse.data;
        setName(documentTypesData[0].name);
        if (documentTypesData[0].allowedUserTypes) {
          const selectedModuleIds = documentTypesData[0].allowedUserTypes
            .split(",")
            .map(Number);
          const selectedModules = usernamesAndIds.filter((module) =>
            selectedModuleIds.includes(module.id)
          );
          setSelectedModules(selectedModules);
        } else {
          setSelectedModules([]);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let payload = {
      name: name,
      allowedUserTypes: selectedModules ? selectedModules.map((m) => m.id).join(",") : null
    };

    // if (selectedModules && selectedModules.length > 0) {
    //   payload["allowedUserTypes"] = selectedModules.map((m) => m.id).join(",");
    // }
    // else {
    //   alert("Please select at least one user");
    //   return;
    // }
    // console.log(payload, "payload");
    if (Number(id) == 0) {
      try {
        await axios.post(BaseURL + "documentTypes", payload);
        toast.success("Document Type Created Successfully!");
        navigate("/document_type_master");
      } catch (error) {
        console.error("Error:", error);
        toast.error("Something went Wrong!");
      }
    } else {
      try {
        const formattedPayload = {
          ...payload,
          lastModified: new Date().toISOString().slice(0, 19).replace("T", " "),
        };
        await axios.patch(
          BaseURL + `documentTypes/${Number(id)}`,
          formattedPayload
        );
        toast.success("Document Type Updated Successfully!");
        navigate("/document_type_master");
      } catch (error) {
        console.error("Error:", error);
        toast.error("Something went Wrong!");
      }
    }
  };

  useEffect(() => {
    getData();
  }, [id]);

  return (
    <Box>
      <Typography variant="h5">Create Document Type Master</Typography>
      <form onSubmit={handleSubmit}>
        <Grid sx={{ my: 2 }} container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                required
                id="Document_name"
                label="Document name "
                variant="outlined"
                name="name"
                value={name || ""}
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <Autocomplete
                multiple
                fullWidth
                id="checkboxes-tags-demo"
                size="small"
                value={selectedModules || []}
                options={modules || []}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name || ""}
                renderOption={(props, option, { selected }) =>(
                    <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />

                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Users (optional)"
                    placeholder="select multiple Users"
                  />
                )}
                onChange={(event, value) => {
                  if (value.length) {
                    setSelectedModules(value);
                  } else {
                    setSelectedModules(null);
                  }
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Button
          sx={{ mt: 2 }}
          variant="contained"
          color="primary"
          type="submit"
        >
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default DocumentTypeMasterID;
