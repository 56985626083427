import { useEffect, useState } from "react";
import {
  Paper,
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  TablePagination,
  Button,
  TextField,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import { BaseURL } from "../global";
import { Link } from "react-router-dom";
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  borderRadius: "15px 15px 0 0",
  maxWidth: "100%",
}));

const StyledTable = styled(Table)(({ theme }) => ({
  minWidth: 550,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.getContrastText(theme.palette.primary.dark),
}));

const EquipmentMaster = () => {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchBy, setSearchBy] = useState("");
  const [searchKey, setSearchKey] = useState("");

  const getData = async () => {
    try {
      let equipmentsResponse = await axios.get(
        BaseURL + "equipments?_sort=-id"
      );
      let equipmentsData = equipmentsResponse.data;
      setRows(equipmentsData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this Machine?"
    );
    if (!confirmDelete) {
      return;
    }
    try {
      await axios.delete(BaseURL + `equipments/${id}`);
      toast.success("Machine Delete Successfully!");
      getData();
    } catch (error) {
      console.error("Error deleting equipment:", error);
      toast.error("Something Went Wrong!");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const calculateIndex = (pageIndex, rowIndex, rowsPerPage) => {
    return pageIndex * rowsPerPage + rowIndex + 1;
  };

  const handleSearch = () => {
    axios
      .post(BaseURL + "dynamic", {
        query: `SELECT * FROM equipments WHERE ${searchBy} LIKE '${searchKey}%'`,
      })
      .then((res) => setRows(res.data))
      .catch((err) => toast.error(err));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Link to="/equipment_master/0">
          <Button variant="contained">Create Machine</Button>
        </Link>
        <Box>
          <Stack direction="row" spacing={2} alignItems="center">
            <Autocomplete
              sx={{
                m: 0,
                minWidth: 180,
                marginLeft: "10px",
              }}
              size="small"
              id="combo-box-demo"
              options={[
                { key: "name", label: "name" },
                { key: "modelNo", label: "modelNo" },
              ]}
              renderInput={(params) => (
                <TextField {...params} label="Search By" />
              )}
              onInputChange={(event, newInputValue) => {
                setSearchBy(newInputValue);
                if (!newInputValue.trim()) {
                  getData();
                  setSearchKey("");
                }
              }}
            />
            <TextField
              id="outlined-basic"
              label={"enter " + searchBy}
              size="small"
              variant="outlined"
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
            />
            <Button variant="contained" onClick={handleSearch}>
              Search
            </Button>
          </Stack>
        </Box>
      </Stack>

      <br />
      <br />
      <Box>
        <StyledTableContainer component={Paper}>
          <StyledTable aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Index</StyledTableCell>
                <StyledTableCell>Machine Name</StyledTableCell>
                <StyledTableCell>Model Number</StyledTableCell>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? rows.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : rows
              )?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {calculateIndex(page, index, rowsPerPage)}
                  </TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.modelNo}</TableCell>
                  <TableCell>
                    {moment(row.lastModified).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell sx={{ p: 0 }}>
                    <Stack direction="row">
                    <Tooltip
                        title="Edit"
                        placement="top-start"
                      >
                        <Button
                        sx={{minWidth:0}}
                        component={Link}
                        to={`/equipment_master/${row.id}`}
                        >
                          <EditIcon color="success" />
                        </Button>
                      </Tooltip>
                      <Tooltip
                        title="Delete"
                        placement="top-start"
                      >
                        <Button
                        sx={{minWidth:0}}
                        color="error"
                        onClick={() => handleDelete(row.id)}
                        >
                        <DeleteIcon  />
                        </Button>
                        </Tooltip>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        ></TablePagination>
      </Box>
    </>
  );
};

export default EquipmentMaster;
