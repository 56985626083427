import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import FolderSpecialRoundedIcon from "@mui/icons-material/FolderSpecialRounded";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import LogoutIcon from "@mui/icons-material/Logout";
import SummarizeRoundedIcon from "@mui/icons-material/SummarizeRounded";
import DriveFolderUploadRoundedIcon from "@mui/icons-material/DriveFolderUploadRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LOGOUT } from "../Redux/auth.types";
import { Button, InputAdornment, Modal, Popover, TextField, Tooltip } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { BaseURL } from "../global";

const drawerWidth = 240;

function Sidebar(props) {
  const { window, children } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authData = localStorage.getItem("auth");
  const userInfo = localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : null;
  const storage_userID = localStorage.getItem("id");
  const modules = authData ? JSON.parse(authData)?.modules : [];

  const [userData, setUserData] = React.useState({
    username: "",
    password: "",
    confirmPassword: "",
  });
  const [passwordVisibility, setPasswordVisibility] = React.useState({
    password: false,
    confirmPassword: false,
  });
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState(null);
  
  const routes = [
    {
      path: "/dashboard",
      text: "Dashboard",
      icon: <GridViewRoundedIcon />,
    },
    {
      path: "/equipment_master",
      text: "Machine Master",
      icon: <FolderSpecialRoundedIcon />,
    },
    {
      path: "/document_type_master",
      text: "Document Type Master",
      icon: <SummarizeRoundedIcon />,
    },
    {
      path: "/document_uploader",
      text: "Document Upload",
      icon: <DriveFolderUploadRoundedIcon />,
    },
    {
      path: "/userlist",
      text: "UserList",
      icon: <ManageAccountsIcon />,
    },
  ];
  const filterRoutesByModules = (routes, allowedModules) => {
    return routes.filter((route, index) => allowedModules?.includes(index + 1));
  };
  const filteredRoutes = filterRoutesByModules(routes, modules);

  const location = useLocation();
  const pathname = location.pathname;
  const routeSegments = pathname.split("/");
  let selectedRoute = routeSegments[1];
  if (routeSegments[1] === "master" && routeSegments.length > 1) {
    selectedRoute = routeSegments[2];
  }
  const convertToTitleCase = (str) => {
    return str
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const selectedRouteInUpperCase = convertToTitleCase(selectedRoute);


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar sx={{ textAlign: "center", bg: "red" }}>
        <img
          src="./logo.png"
          alt="logo"
          style={{
            fontFamily: "monospace",
            fontWeight: 700,
            letterSpacing: ".3rem",
            color: "inherit",
            textDecoration: "none",
            height: "55px",
            width: "40%",
            margin: "auto",
            textAlign: "center",
          }}
        />
      </Toolbar>

      <Divider />
      <List>
        {filteredRoutes.map((route) => (
          <ListItem key={route.text} disablePadding>
            <ListItemButton
              onClick={() => {
                navigate(route.path);
              }}
              sx={{
                minHeight: 48,
                justifyContent: "initial",
                px: 2.5,
              }}
            >
              <ListItemIcon
              // sx={{
              //   color: router.pathname === route.path ? "blue" : null,
              // }}
              >
                {route.icon}
              </ListItemIcon>
              <ListItemText
                primary={route.text}
                // sx={{
                //   color: router.pathname === route.path ? "blue" : null,
                // }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

    const handleTooltipClick = (event) => {
      setPopoverAnchorEl(event.currentTarget);
    };  
    const handlePopoverClose = () => {
      setPopoverAnchorEl(null);
    };
    const isPopoverOpen = Boolean(popoverAnchorEl);
    const popoverId = isPopoverOpen ? "user-profile-popover" : undefined;
   
    const togglePasswordVisibility = (field) => {
      setPasswordVisibility((prevVisibility) => ({
        ...prevVisibility,
        [field]: !prevVisibility[field],
      }));
    };

    const handleInputChange = (field) => (event) => {
      setUserData({ ...userData, [field]: event.target.value });
    };

    const handleModalOpen = () => {
      setIsModalOpen(true);
      handlePopoverClose();
    };
  
    const handleModalClose = () => {
      setIsModalOpen(false);
    };

    const passwordChangeModal = () => {
      return (
        <Modal
          open={isModalOpen}
          onClose={handleModalClose}
          aria-labelledby="edit-profile-modal"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 550,
              "@media (max-width: 600px)": {
                width: "80%", // Adjust the width for screens up to 768px wide
              },
              "@media (max-width: 480px)": {
                width: "90%", // Adjust the width for screens up to 480px wide
              },
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 2,
              maxHeight: "550px",
              overflow: "auto",
              padding: "30px",
            }}
          >
            <Typography variant="h6" gutterBottom>
              Edit Password
            </Typography>
            <TextField
              disabled
              value={userData.username || ""}
              onChange={handleInputChange("username")}
              fullWidth
              margin="normal"
            />
            <TextField
              label="New Password"
              type={passwordVisibility.password ? "text" : "password"}
              value={userData.password}
              onChange={handleInputChange("password")}
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => togglePasswordVisibility("password")}
                    >
                      {passwordVisibility.password ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Confirm Password"
              type={passwordVisibility.confirmPassword ? "text" : "password"}
              value={userData.confirmPassword}
              onChange={handleInputChange("confirmPassword")}
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => togglePasswordVisibility("confirmPassword")}
                    >
                      {passwordVisibility.confirmPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveChanges}
            >
              Save Changes
            </Button>
          </Box>
        </Modal>
      );
    };

    const handleSaveChanges = async () => {
      if (userData.password !== userData.confirmPassword) {
        toast.error("New password and confirm password do not match");
        return;
      }
      try {
        const response = await axios.patch(
          BaseURL+`/users/${storage_userID}`,
          {
            password: userData.password,
          }
        );
  
        if (response.status === 200) {
          toast.success("Password updated successfully");
          handleModalClose();
        } else {
          console.error("Failed to update password");
        }
      } catch (error) {
        console.error("Error updating password:", error);
      }
      handlePopoverClose();
    };
  
    React.useEffect(() => {
      const fetchUserData = async () => {
        try {
          const response = await axios.get(
            BaseURL+`/users/${storage_userID}`
          );
  
          if (response.status === 200) {
            const userData = response.data;
            setUserData({
              username: userData[0].userName,
            });
          } else {
            console.error("Failed to fetch user data");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };
  
      fetchUserData();
    }, []);

  return (
    <>
    {passwordChangeModal()}
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
              align="left"
            >
              {selectedRouteInUpperCase}
            </Typography>
            <Tooltip>
              <IconButton
                size="small"
                style={{ backgroundColor: "white", marginRight: "5px" }}
                disabled
              >
                <AccountCircleIcon style={{ color: "#1976d2" }} />
              </IconButton>
          </Tooltip>
            <Typography
              variant="h6"
              color="inherit"
              sx={{ cursor: "pointer", mr: 2 }}
              onClick={handleTooltipClick}
            >
              {userInfo.userName}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Toolbar />
          {children}
        </Box>

        <Popover
        id={popoverId}
        open={isPopoverOpen}
        anchorEl={popoverAnchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <List>
          <ListItem>
            <Button onClick={handleModalOpen}>
              <RestartAltIcon style={{ marginRight: "10px" }} />
              Reset Password
            </Button>
          </ListItem>
          <ListItem>
            <Button  onClick={() => {
                dispatch({ type: LOGOUT });
                navigate("/");
                sessionStorage.clear();
                localStorage.clear();
              }}>
              <LogoutIcon style={{ marginRight: "10px" }} />
              Logout
            </Button>
          </ListItem>
        </List>
      </Popover>
      </Box>
    </>
  );
}

export default Sidebar;
