import axios from "axios";
import { BaseURL } from "../global";
import { saveData } from "../UTIL";
import { LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS } from "./auth.types";

export const authlogin =
  ({ userName, password }) =>
  (dispatch) => {
    dispatch({ type: LOGIN_REQUEST });
    return axios
      .get(
        BaseURL +
          `users?_where=(userName,eq,${userName})~and(password,eq,${password})`
      )
      .then((r) => {
        if (r.data.length > 0) {
          saveData("auth", JSON.stringify(r.data[0]));
          saveData("id", JSON.stringify(r.data[0]?.id));
          dispatch({ type: LOGIN_SUCCESS, payload: r.data[0] });
          return LOGIN_SUCCESS;
        }
      })
      .catch((err) => {
        dispatch({ type: LOGIN_FAILURE, payload: err });
        return LOGIN_FAILURE;
      });
  };
