import { useEffect, useState } from "react";
import {
  Paper,
  Box,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  TablePagination,
  Button,
  TextField,
  Autocomplete,
  Typography,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import { BaseURL } from "../global";
import { Link } from "react-router-dom";
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  borderRadius: "15px 15px 0 0",
  maxWidth: "100%",
}));

const StyledTable = styled(Table)(({ theme }) => ({
  minWidth: 550,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.getContrastText(theme.palette.primary.dark),
}));

const DocumentTypeMaster = () => {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchBy, setSearchBy] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [userIdToUsernameMap, setUserIdToUsernameMap] = useState({});
  const getData = async () => {
    try {
      const docTypesResponse = await axios.get(
        BaseURL + "documentTypes?_sort=-id"
      );
      const usersResponse = await axios.get(BaseURL + "users");

      const docTypesData = docTypesResponse.data;
      const usersData = usersResponse.data;

      setRows(docTypesData);
      const userIdMap = {};
      usersData.forEach((user) => {
        userIdMap[user.id] = user.userName;
      });
      setUserIdToUsernameMap(userIdMap);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this document type?"
    );
    if (!confirmDelete) {
      return;
    }
    try {
      await axios.delete(BaseURL + `documentTypes/${id}`);
      toast.success("Document Type Delete Successfully!");
      getData();
    } catch (error) {
      toast.error("Something Went Wrong!");
      console.error("Error deleting equipment:", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const calculateIndex = (pageIndex, rowIndex, rowsPerPage) => {
    return pageIndex * rowsPerPage + rowIndex + 1;
  };

  const handleSearch = () => {
    let query = "";
    if (searchBy === "name") {
      query = `SELECT * FROM documentTypes WHERE name LIKE '${searchKey}%'`;
    } else {
      query = `SELECT dt.* FROM documentTypes dt
      JOIN users u ON FIND_IN_SET(u.id, dt.allowedUserTypes) WHERE u.userName LIKE '${searchKey}%'`;
    }
    axios
      .post(BaseURL + "dynamic", {
        query: query,
      })
      .then((res) => setRows(res.data))
      .catch((err) => toast.error(err));
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Link to="/document_type_master/0">
          <Button variant="contained">Create Document</Button>
        </Link>
        <Box>
          <Stack direction="row" spacing={2} alignItems="center">
            <Autocomplete
              sx={{
                m: 0,
                minWidth: 180,
                marginLeft: "10px",
              }}
              size="small"
              id="combo-box-demo"
              options={[
                { key: "name", label: "name" },
                { key: "allowedUserTypes", label: "allowedUserTypes" },
              ]}
              renderInput={(params) => (
                <TextField {...params} label="Search By" />
              )}
              onInputChange={(event, newInputValue) => {
                setSearchBy(newInputValue);
                if (!newInputValue.trim()) {
                  getData();
                  setSearchKey("");
                }
              }}
            />
            <TextField
              id="outlined-basic"
              label={"enter " + searchBy}
              size="small"
              variant="outlined"
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
            />
            <Button variant="contained" onClick={handleSearch}>
              Search
            </Button>
          </Stack>
        </Box>
      </Stack>

      <br />
      <br />
      <Box>
        <StyledTableContainer component={Paper}>
          <StyledTable aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Index</StyledTableCell>
                <StyledTableCell>Document Name</StyledTableCell>
                <StyledTableCell>Users</StyledTableCell>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? rows.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : rows
              )?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {calculateIndex(page, index, rowsPerPage)}
                  </TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>
                    {row.allowedUserTypes
                      ?.split(",")
                      .map(Number)
                      .filter((userId) => userIdToUsernameMap[userId])
                      .map((userId, index) => (
                        <Typography
                          key={index}
                          variant="paragraph"
                          sx={{ textTransform: "capitalize" }}
                          component="div"
                        >
                          {userIdToUsernameMap[userId]},
                        </Typography>
                      ))}
                  </TableCell>
                  <TableCell>
                    {moment(row.lastModified).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell sx={{ p: 0 }}>
                    <Stack direction="row">
                    <Tooltip
                        title="Edit Document Type Master"
                        placement="top-start"
                      >
                        <Button
                        component={Link} sx={{minWidth:0}}
                        to={`/document_type_master/${row.id}`}
                        >
                          <EditIcon color="success" />
                        </Button>
                      </Tooltip>
                      <Tooltip
                        title="Delete Document Type Master"
                        placement="top-start"
                      >
                        <Button
                        sx={{minWidth:0}}
                        onClick={() => handleDelete(row.id)}
                        color="error"
                        >
                        <DeleteIcon  />
                        </Button>
                      </Tooltip>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        ></TablePagination>
      </Box>
    </>
  );
};

export default DocumentTypeMaster;
