import { del, getLocalData } from "../UTIL/index";

import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
} from "./auth.types";

const initState = {
  isAuth: getLocalData("auth") ? true : false,
  isLoading: false,
  isError: false,
};

const authReducers = (state = initState, { type, payload }) => {
  switch (type) {
    case LOGIN_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isAuth: true,
      };
    }
    case LOGIN_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        isAuth: false,
      };
    }
    case LOGOUT: {
      del("auth");
      return {
        ...state,
        isLoading: false,
        isAuth: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default authReducers;
