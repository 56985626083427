import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { BaseURL } from "../global";

const EquipmentID = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form, setForm] = useState({ name: "", modelNo: "" });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const getData = async () => {
    if (Number(id) == 0) {
      setForm({ name: "" });
    } else {
      try {
        let equipmentResponse = await axios.get(BaseURL + `equipments/${Number(id)}`);
        let equipmentData = equipmentResponse.data;
        setForm(equipmentData[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (Number(id) == 0) {
      try {
        await axios.post(BaseURL + "equipments", form);
        toast.success("Machine Created Successfully!");
        navigate("/equipment_master");
      } catch (error) {
        console.error("Error:", error);
        toast.error("Something went Wrong!");
      }
    } else {
      try {
        const formattedForm = {
          ...form,
          lastModified: new Date().toISOString().slice(0, 19).replace("T", " "),
        };
        await axios.patch(BaseURL + `equipments/${Number(id)}`, formattedForm);
        toast.success("Machine Updated Successfully!");
        navigate("/equipment_master");
      } catch (error) {
        console.error("Error:", error);
        toast.error("Something went Wrong!");
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <Box>
      <Typography variant="h5">Machine Master Form</Typography>
      <form onSubmit={handleSubmit}>
        <Grid sx={{ my: 2 }} container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                required
                id="name"
                label="Machine name "
                variant="outlined"
                name="name"
                value={form.name || ""}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                required
                id="name"
                label="Model Number "
                variant="outlined"
                name="modelNo"
                value={form.modelNo || ""}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Button
          sx={{ mt: 2 }}
          variant="contained"
          color="primary"
          type="submit"
        >
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default EquipmentID;
