import { useEffect, useState } from "react";
import axios from "axios";
import { BaseURL } from "../global";
import { PersonAdd, ShoppingBasket } from "@mui/icons-material";
import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import QrCode2Icon from "@mui/icons-material/QrCode2";

const Dashboard = () => {
  const [equipment, setequipment] = useState("");
  const [document, setdocument] = useState("");
  const [qrCode, setqrCode] = useState("");
  const cardData = [
    {
      number: equipment.length,
      text: "Total Machine",
      icon: <ShoppingBasket size={80} />,
    },
    {
      number: document.length,
      text: "Total Documents Type",
      icon: <PersonAdd size={80} />,
    },
    {
      number: qrCode.length,
      text: "Total QR ",
      icon: <QrCode2Icon size={80} />,
    },
  ];

  const getData = async () => {
    try {
      let equipmentsResponse = await axios.get(BaseURL + "equipments");
      let documentTypesResponse = await axios.get(BaseURL + "documentTypes");
      let equipmentDocsQRResponse = await axios.get(
        BaseURL + `equipmentDocs?_sort=-lastModified`
      );
      const uniqueEquipmentIds = new Set();
      const distinctEquipmentIds = [];
      equipmentDocsQRResponse?.data.forEach((item) => {
        const key = `${item.equipmentId}-${item.modelNo}-${item.SerialNo}`;
        if (!uniqueEquipmentIds.has(key)) {
          uniqueEquipmentIds.add(key);
          distinctEquipmentIds.push(item);
        }
      });

      setequipment(equipmentsResponse.data);
      setdocument(documentTypesResponse.data);
      setqrCode(distinctEquipmentIds);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>

      <Grid container spacing={5}>
        {cardData?.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper elevation={3} className="linear_gradientbix">
              <Stack justifyContent="space-between" direction="row" spacing={2}>
                <div>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    color="#fff"
                    fontSize="2rem"
                  >
                    {card.number}
                  </Typography>
                  <Typography
                    color="#f3e5f5"
                    variant="subtitle1"
                    fontWeight="500"
                  >
                    {card.text}
                  </Typography>
                </div>
                <Box className="iconsbox">
                  <Typography
                    color="white"
                    variant="subtitle1"
                    fontWeight="bold"
                  >
                    {card.icon}
                  </Typography>
                </Box>
              </Stack>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Dashboard;
